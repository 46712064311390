/* eslint-disable jsx-a11y/heading-has-content */
import React from "react";

import StaticLayout from "../components/StaticLayout";
import imgSrc from "../images/dance.jpg";

const Page = () => (
  <StaticLayout isSingle>
    <main id="main" className="site-main" role="main">
      <div className="svg-container single-post-svg svg-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 1890 150"
        >
          <g transform="translate(0,-902.36218)" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 1925,0 0,150 -1925,0" />
        </svg>
      </div>
      <article
        id="post-27"
        className="post-27 page type-page status-publish hentry"
      >
        <header className="entry-header">
          <h1 className="entry-title">Danspartner</h1>{" "}
        </header>
        {/* .entry-header */}
        <div className="entry-content">
          <p>
            <img
              className="size-medium wp-image-184 aligncenter"
              src={imgSrc}
              alt=""
              width={300}
              height={169}
            />
          </p>
          <p>
            Heeft de dansmicrobe jou te pakken, maar heb je nog geen partner?
          </p>
          <p>
            Wij helpen je graag met je zoektocht. Mail je gegevens (naam,
            voornaam, leeftijd, lengte, danservaring, welke cursus je wenst te
            volgen en eventueel een foto) naar ons door en wij proberen een
            geschikte partner te vinden voor jou.
          </p>
          <p>
            Als je dat wenst, kunnen we je gegevens op onze website plaatsen, zo
            maak je meer kans om snel een goede match te vinden.
          </p>
          <h2 />
          <h2 />
          <h2 />
        </div>
        {/* .entry-content */}
        <footer className="entry-footer" />
        {/* .entry-footer */}
      </article>
      {/* #post-## */}
      <div className="svg-container single-post-svg single-svg-bottom svg-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 1950 150"
        >
          <g transform="translate(0,-902.36218)" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="M 0,150 0,0 1950,0" />
        </svg>
      </div>
    </main>
  </StaticLayout>
);

export default Page;
